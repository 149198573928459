import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet tot ell groc llimona excepte la part inferior del peu que és més blanquinosa i les làmines que amb l’edat prenen tons rogencs. Té un capell campanulat de 5 a 6 cm de diàmetre amb un mamelló. De vell esdevé més pla i presenta unes fibril·les innates de color groc. Les làmines són escotades i ventrudes i el peu és llarg, torçat i fibrós. Les espores són blanques en massa, subgloboses, d’11,5-13,7 x 8,5-10,6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      